<template>
  <div class="columns is-multiline has-text-centered">
    <div class="column is-12 has-text-centered is-paddingless is-marginless titleCard">
      Últimos 5 productos
    </div>
    <div class="column is-12 separator"></div>
    <div class="column is-12 has-text-centered">
      <template v-if="similarProducts.length === 0">
        <p class="withoutProducts">No hay productos relacionados</p>
      </template>
      <template v-else>
        <div class="columns is-multiline has-text-centered">
          <template v-for="(product, index) of similarProducts">
            <div class="column is-12 has-text-centered productList" :key="index">
              <router-link :to="{ name: 'detalle-producto', params: { productId: product.productId } }">
                <article class="media">
                  <div class="media-left">
                    <div class="thumbnailImage" :style="`background-image: url('${product.url}')`"></div>
                  </div>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        <small>{{product.name}}</small>
                      </p>
                    </div>
                  </div>
                </article>
              </router-link>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    similarProducts: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
  .productList {
    border-bottom: 1px solid #FAFAF1;
  }
  .thumbnailImage {
    transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
    border-radius: 25px;
  }
  .productList a {
    color: var(--secondary-color);
  }
  .subtitle {
    font-size: 16px;
  }
  .withoutProducts {
    font-size: 14px;
  }
  .productList:hover {
    background: #FAFAFA;
  }
  .productList a:hover {
    color: var(--primary-color);
  }
  .productList a:hover .thumbnailImage {
    transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    border-radius: 4px;
  }
</style>
